export const SUBSCRIPTION_GIFT_PATH = '/checkout/subscribe/gift';

export const PRODUCTS_PATH = '/products';

export const PAYMENT_ELEMENT_DARK_THEME = 'night';
export const MEXICO_COUNTRY_CODE = 'MX';

export const DEFAULT_LOCALE = 'en';
export const DETECTED_LOCALE = 'locale_det';
export const LOCALE = 'locale';
